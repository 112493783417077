const quote_items =
    [
        {
            name: 'girlsCamp',
            imgPath: '/images/Compressed/photos/girlsCamp.jpg',
            quote: 'You can make something you love', 
            des: 'Female',
            info: 'Age 7, Toronto'
        },
        {
            name: 'MABrobotics',
            imgPath: '/images/Compressed/photos/MABrobotics.jpg',
            quote: 'I love building things and taking them apart', 
            des: 'Male',
            info: 'Age 9, Toronto'
        },
        {
            name: 'MinecraftCamp',
            imgPath: '/images/Compressed/photos/MinecraftCamp.jpg',
            quote: 'I get to build things with my friends', 
            des: 'Female',
            info: 'Toronto'
        },
        {
            name: 'OSC',
            imgPath: '/images/Compressed/photos/OSC.jpg',
            quote: 'An amazing experience', 
            des: 'Male',
            info: 'Toronto'
        },
    ]
    export default quote_items