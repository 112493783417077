import React from "react";
import { Paper, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styled, { keyframes } from "styled-components";
import { slideInUp } from "react-animations";

const useStyles = makeStyles((theme) => ({
    item_anchor: {
        textDecoration: "none",
        "&:hover": {
            cursor: "pointer",
        },
    },
    paper: {
        color: "#fff",
        backgroundColor: "#f37423",
        backgroundRepeat: "no-repeat ",
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        borderRadius: "15px",
        height: "300px",
        boxShadow: "0 6px 13px 0 rgb(171,171,171, 0.8)",
    },
    textbox: {
        backgroundColor: "#fff",
        width: "100%",
        borderRadius: "0 0 15px 15px",
        color: "#000",
        padding: "10px",
    },
    desContainer: {
        overflow: "hidden",
    },
    activitiesButton: {
        width: "100%",
        margin: "5px auto",
        padding: "2px",
        borderRadius: "29px",
        border: 0,
        cursor: "pointer",
    },
}));

const slideAnimation = keyframes`${slideInUp}`;

const SlideDiv = styled.div`
    animation: 0.4s ${slideAnimation};
`;

export default function CardBox(props) {
    const classes = useStyles();
    const [desShown, setDesShown] = React.useState(false);
    let multAct = "";
    if (props.item.paths) {
        const colors = ["#f37423", "#e19f26", "#5daac7", "#e3202c", "#168c75"];
        multAct = props.item.paths.map((item, id) => {
            return (
                <div key={id}>
                    <a href={item[1]}>
                        <button
                            className={classes.activitiesButton}
                            style={{ backgroundColor: colors[id] }}
                        >
                            <Typography variant="subtitle1">
                                <strong style={{ color: "#fff" }}>
                                    {item[0]}
                                </strong>
                            </Typography>
                        </button>
                    </a>
                </div>
            );
        });
    }

    const card = (
        <Paper
            className={classes.paper}
            style={{
                backgroundImage: `url(${
                    process.env.PUBLIC_URL + props.item.imgPath
                })`,
            }}
            onMouseOver={() => setDesShown(true)}
            onMouseLeave={() => setDesShown(false)}
        >
            <Grid
                container
                justify="flex-start"
                alignItems="flex-end"
                style={{ height: "100%" }}
            >
                <Grid item container xs={12} className={classes.textbox}>
                    <Grid item className={classes.desContainer}>
                        <Typography variant="subtitle1">
                            <strong style={{ fontSize: "1.3rem" }}>
                                {props.item["name"].toUpperCase()}
                            </strong>
                        </Typography>

                        <SlideDiv
                            style={{ display: desShown ? "block" : "none" }}
                        >
                            <Typography variant="caption">
                                <strong style={{ fontSize: "0.9rem" }}>
                                    {props.item["des"].toUpperCase()}
                                </strong>
                            </Typography>
                            {props.item.paths && multAct}
                        </SlideDiv>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );

    return (
        <>
            {!props.item.paths ? (
                <a className={classes.item_anchor} href={props.item.sitePath}>
                    {card}
                </a>
            ) : (
                <>{card}</>
            )}
        </>
    );
}
