import React from 'react'
import { Paper, Typography, Grid, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        color: "#fff",
        backgroundColor: "#fff",
        borderRadius: '29px',
        height: '275px',
        width: '90%',
        maxWidth: '560px',
        margin: '10px 8px',
        border: '2px solid #E19F26',
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            height: '275px',
            overflow: 'hidden',
            borderRadius: '29px',
            border: '3px solid #E19F26'
        },

    },
    textbox: {
        width: '100%',
        color: '#000',
        borderRadius: '29px',
        padding: '40px 30px',
        position: 'relative',
        height: '275px',
        [theme.breakpoints.only('xs')]: {
            position: 'static',
            height: 'fit-content',
            padding: '0',
            margin: '0 auto 50px',
            color: '#fff4f4'
        },
    },
    imgBox: {
        width: '330px',
        height: '275px',
        overflow: 'hidden',
        borderRadius: '29px 0 0 29px',
        border: '1px solid #E19F26'

    },
    cardImg: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    info: {
        position: 'absolute',
        bottom: '12px',
        [theme.breakpoints.only('xs')]: {
            position: 'static',
        },
    }
}));


export default function QuoteBox(props) {
    const classes = useStyles();
    return (
        <>
            <Box display={{ xs: 'none', sm: 'block' }}>
                <Paper className={classes.paper} >
                    <Grid container justify="flex-start" alignItems="center" direction="row">
                        <Grid item xs={7} className={classes.imgBox}>
                            <img src={process.env.PUBLIC_URL + props.item.imgPath} className={classes.cardImg} alt="quote " />
                        </Grid>
                        <Grid item xs={5} container direction="column" justify="center" alignItems="flex-start" className={classes.textbox}>
                            <Grid item xs={12}><Typography variant="subtitle2" ><strong>"{props.item['quote']}"</strong></Typography></Grid>
                            <Typography className={classes.info} variant="subtitle2" ><strong>{props.item['des']}<br />{props.item['info']}</strong></Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <Box display={{ xs: 'block', sm: 'none' }}>
                <Paper className={classes.paper} >
                    <img src={process.env.PUBLIC_URL + props.item.imgPath} alt="quote "  className={classes.cardImg} />
                </Paper>
                <Grid  container justify="center" alignItems="flex-start" className={classes.textbox} spacing={2}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}><Typography variant="h4" >"{props.item['quote']}"</Typography></Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}><Typography className={classes.info} variant="h4" >{props.item['des']}<br />{props.item['info']}</Typography></Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </Box>
        </>
    )
}