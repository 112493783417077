import React, {createRef} from 'react';
import useWindowDimensions from '../Window';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Paper, Typography, Button } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import styled, { keyframes } from 'styled-components';
import { pulse } from 'react-animations';
import CardBox from './CardBox';
import QuoteBox from '../QuoteBox';
import EmailBox from '../EmailBox';
import carousel_items from "../../assets/carousel_items.js";
import quote_items from "../../assets/quote_items.js";
import backgroundImg from '../../assets/images/Compressed/home/background.jpg';
import design from '../../assets/images/Compressed/howItWorks/Design.jpg'
import produce from '../../assets/images/Compressed/howItWorks/Produce.jpg'
import producePrint from '../../assets/images/Compressed/howItWorks/ProducePrint.jpg'
import assemble from '../../assets/images/Compressed/howItWorks/Assemble.jpg'
import ontarioScienceCentre from '../../assets/images/Compressed/logos/ontarioScienceCentre.jpg'
import SteamLabsWordmark from '../../assets/images/Compressed/logos/SteamLabsWordmark.jpg'
import amazonLogo from '../../assets/images/Compressed/logos/amazonLogo.png'
import homeComp from '../../assets/images/Compressed/home/HomeComp.png';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto ',
    },
    sctnOne: {
        backgroundImage: `url('${backgroundImg}')`,
        backgroundRepeat: 'no-repeat ',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: '900px',
        width: '100%',
        backgroundColor: '#17947c',
        [theme.breakpoints.down('sm')]: {
            backgroundPosition: 'inherit',
        },
    },
    sctnTwo: {
        height: 'fit-content',
        width: '100%',
        backgroundColor: '#f37423'
    },
    sctnThree: {
        height: 'fit-content',
        width: '100%',
        backgroundColor: '#eae8e8'
    },
    activity_container:{
        margin: '150px 0'
    },
    sctnFour: {
        height: 'fit-content',
        width: '100%',
        backgroundColor: '#5daac7'
    },
    sctnFive: {
        height: 'fit-content',
        width: '100%',
        backgroundColor: '#fff',
        padding: '30px 0'
    },
    sctnSix: {
        height: 'fit-content',
        width: '100%',
        padding: '65px 0',
        backgroundColor: '#83beb3'
    },
    sctnSeven: {
        height: 'fit-content',
        width: '100%',
        backgroundColor: '#eae8e8'
    },
    headingOne: {
        color: '#fff',
        fontWeight: 'bold',
        [theme.breakpoints.down('xl')]: {
            fontSize: '45px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '30px',
        },
    },
    headingTwo: {
        color: '#fff',
        width: 'fit-content',
        margin: '0 auto 10px',
        [theme.breakpoints.down('xl')]: {
            fontSize: '1.4rem',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '15px',
            fontSize: '1.1rem',
        },
    },
    paragraph: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '20px',
    },
    headerBtn: {
        position: 'relative',
        right: '10%',
        margin: '25px 0 40px',
        backgroundColor: "#f37423",
        color: "#fff",
        borderRadius: '29px',
        border: '3px solid #fff',
        boxShadow: '0 4px 8px 0 rgba(0,0,0, 0.3)',
        padding: '12px',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: "#d36620",
            boxShadow: '0 4px 8px 0 rgba(10,10,10, 0.8)',
        },
        [theme.breakpoints.down('xs')]: {
            right: '0',
        },
    },
    paperOvrly: {
        height: 'fit-content',
        width: '100%',
        zIndex: '2',
        borderRadius: '60px',
        position: 'relative',
        top: '-50px'
    },
    paperTop: {
        height: '209px',
        width: '90%',
        zIndex: '2',
        borderRadius: '60px',
        position: 'absolute',
        top: '-104.5px',
        left: '5%',
        backgroundColor: '#5daac7'
    },
    ftrAct: {
        width: '100%',
        margin: '3% auto',
    },
    carousel: {
        position: 'static',
        overflow: 'hidden',
        width: 'fit-content',
        margin: 'auto',

    },
    carouselPaper: {
        height: '100px',
        width: '200px',
        backgroundSize: 'cover',
        backgroundColor: 'rgb(248 236 212)',
        position: 'relative',
        boxShadow: '0 4px 8px 0 rgba(0,0,0, 0.8)',
        borderRadius: '15px'

    },
    item_anchor:{
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '0 4px 8px 0 rgba(10,10,10, 0.8)',
        },
    },
    carItemHead: {
        backgroundColor: '#fff',
        color: '#000',
        width: 'fit-content',
        padding: '2px',
        margin: ' 0',
        position: 'absolute',
        bottom: '6px',
        left: '6px',
        borderRadius: '6px',
        fontSize: '1.2vw',
        [theme.breakpoints.only('sm')]: {
            fontSize: '2.3vw',
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: '3.5vw',
        },
    },
    cardNum: {
        fontSize: '40px',
        fontWeight: 'bold',
        marginRight: '10px'
    },
    cardHead: {
        color: '#4eb5fd',
        marginRight: '10px'
    },
    cardImg: {
        maxHeight: '211px',
        width: 'auto',
        maxWidth: '200px',
        borderRadius: '30%'
    },
    cardDes: {
        width: '90%'
    },
    cardDetTitle: {
        color: '#4eb5fd',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1.2rem'
    },
    cardDet: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1rem'
    },
    activityBtn: {
        border: '2px solid #4eb5fd',
        borderRadius: '29px',
        color: '#4eb5fd',
        width: '100%',
        minWidth: 'fit-content',
        margin: '20px 0',
        fontSize: '1rem'
    },
    emptyTagContainer: {
        padding: 'inherit',
        [theme.breakpoints.down('sm')]: {
            zIndex: 1,
            backgroundColor: 'rgb(93,170,199,0.86)',
            width: '100%'
        },
    },
    tagContainer: {
        padding: '75px 0',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            zIndex: 1,
            backgroundColor: 'rgb(93,170,199,0.86)',
            width: '100%'
        },
        [theme.breakpoints.up('xl')]: {
            left: '220px'
        },
    },
    tagDes: {
        color: '#fff',
        width: '80%',
        margin: '20px 0'
    },
    homeImg: {
        position: 'absolute',
        right: '3px',
        [theme.breakpoints.down('xs')]: {
            zIndex: 0,
            right: '0px',
        },
        [theme.breakpoints.up('xl')]: {
            right: '300px',
        },
    },
    supportContainer: {
        padding: '80px 0 50px',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '150px 0 50px',
        },
    },
    supporterHead: {
        borderBottom: '9px solid black',
        color: '#000',
        fontWeight: 'bold',
        width: 'fit-content',
        [theme.breakpoints.down('xl')]: {
            fontSize: '40px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '30px',
        },
    },
    supporterImg: {
        width: '100%',
        margin: '10px 0'
    },
    testimonialHead: {
        color: '#fff',
        fontWeight: 'bold',
        padding: '20px',
        backgroundColor: '#E19F26',
        width: 'fit-content',
        textShadow: '2px 2px 8px #000',
        [theme.breakpoints.down('xl')]: {
            fontSize: '40px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '30px',
        },
    },
    paperEmail: {
        height: 'fit-content',
        width: '100%',
        borderRadius: '60px',
        backgroundColor: '#E19F26',
        boxShadow: '0 4px 8px 0 rgba(0,0,0, 0.7)',
        [theme.breakpoints.only('xs')]: {
            borderRadius: '0',
        },

    },
    paperEmailContainer: {
        width: 'fit-content',
        padding: '50px 0',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '10px 0 50px',
        },
    },
    paperContainer: {
        padding: '50px',
        [theme.breakpoints.only('xs')]: {
            padding: '0',
        },
    }
}));

const wobbleAnimation = keyframes`${pulse}`;

const WobbleDiv = styled.div`
  animation: 3s ${wobbleAnimation} infinite;
`;

function Item(props) {
    const classes = useStyles();

    return (
        <Grid item >
            <a className={classes.item_anchor} href={props.item.sitePath}>
                <Paper className={classes.carouselPaper} style={{ backgroundImage: `url(${props.item.imgPathFeat})`, backgroundRepeat: 'no-repeat ', backgroundPosition: 'center' }}></Paper>
            </a>
        </Grid>

    )
}

const HomePage = () => {
    let scrollDiv = createRef();

    const scrollSmoothHandler = () => {
        scrollDiv.current.scrollIntoView({ behavior: "smooth" });
    };

    const classes = useStyles();
    const { width } = useWindowDimensions();

    let activitySectionsL = [];
    let activitySectionsM = [];
    let quoteSections = [];
    var i, j, tempAarray, tempQarray, chunkM = 2;
    var chunkL = 3;
    for (i = 0, j = carousel_items.length; i < j; i += chunkL) {
        tempAarray = carousel_items.slice(i, i + chunkL);
        activitySectionsL.push(tempAarray);
    }
    for (i = 0, j = carousel_items.length; i < j; i += chunkM) {
        tempAarray = carousel_items.slice(i, i + chunkM);
        activitySectionsM.push(tempAarray);
    }
    for (i = 0, j = quote_items.length; i < j; i += 2) {
        tempQarray = quote_items.slice(i, i + 2);
        quoteSections.push(tempQarray);
    }

    const activities = carousel_items.map((res, idx) => {
        return (
            <Grid item xs={12} md={6} lg={4} key={idx}>
                <CardBox
                    item={res}
                    key={idx}
                />
            </Grid>
        )
    })



    return (
        <div className={classes.root}>
            <Grid container >
                <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.sctnOne}
                >
                    <Grid item xs={1} sm={2} md={2}></Grid>
                    <Grid container item xs={10} sm={8} md={3} justify="center">
                        <Typography variant="h3" className={classes.headingOne}>FUN, ARTISTIC TECHNOLOGY EXPERIMENTS!</Typography>
                        <br />
                        <Typography className={classes.paragraph}>
                            Unleash your creativity using artificial intelligence and digital design tools.
                        </Typography>
                        <br />
                        <Typography className={classes.paragraph}>
                            Bring your inventions to life by printing at home, or ordering a laser cut version from us!
                        </Typography>
                        <Button variant="contained" className={classes.headerBtn} onClick={scrollSmoothHandler}>
                            <Typography className={classes.paragraph}>
                                CHOOSE AN ACTIVITY                            
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={1} sm={2} md={7}></Grid>
                </Grid>
                <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.sctnTwo}
                >
                    <Grid item xs={1} sm={2} md={2}></Grid>
                    <Grid container item xs={10} sm={8} md={8} xl={6} justify="center" alignItems="center">
                        <Paper className={classes.paperOvrly}>
                            <Paper className={classes.paperTop}>
                                <Grid container direction='column' alignItems="center" justify="center">
                                    <Grid item xs={12} className={classes.ftrAct}>
                                        <Typography variant="h5" className={classes.headingTwo}>FEATURED ACTIVITIES!</Typography>
                                        {width >= 1280 &&
                                            <Carousel
                                                animation={'slide'}
                                                autoPlay={false}
                                                navButtonsProps={{
                                                    style: {
                                                        color: '#5daac7',
                                                        backgroundColor: '#fff'
                                                    }
                                                }}
                                                className={classes.carousel}
                                            >
                                                {
                                                    activitySectionsL.map((item, i) => {
                                                        return (
                                                            <Grid key={i} container direction='row' spacing={2}>
                                                                {
                                                                    item.map((itemN, index) => {
                                                                        return (
                                                                            <>
                                                                                <Item key={index} item={itemN} />
                                                                            </>
                                                                        );
                                                                    })
                                                                }
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Carousel>
                                        }
                                        {(width >= 960 && width < 1280) &&
                                            <Carousel
                                                animation={'slide'}
                                                autoPlay={false}
                                                navButtonsProps={{
                                                    style: {
                                                        color: '#5daac7',
                                                        backgroundColor: '#fff'
                                                    }
                                                }}
                                                className={classes.carousel}
                                            >
                                                {
                                                    activitySectionsM.map((item, i) => {
                                                        return (
                                                            <Grid key={i * 10} container direction='row' spacing={2}>
                                                                {
                                                                    item.map((itemN, iN) => {
                                                                        return (
                                                                            <>
                                                                                <Grid key={iN} item>
                                                                                    <Item item={itemN} />
                                                                                </Grid>
                                                                            </>
                                                                        );
                                                                    })
                                                                }
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Carousel>
                                        }
                                        {width < 960 &&
                                            <Carousel
                                                animation={'slide'}
                                                autoPlay={false}
                                                navButtonsProps={{
                                                    style: {
                                                        color: '#5daac7',
                                                        backgroundColor: '#fff'
                                                    }
                                                }}
                                                className={classes.carousel}
                                            >
                                                {
                                                    carousel_items.map((item, i) => {

                                                        return (
                                                            <>
                                                                <Grid key={i * 100} item>
                                                                    <Item item={item} />
                                                                </Grid>
                                                            </>
                                                        );

                                                    })
                                                }
                                            </Carousel>
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Grid container direction='column' justify="flex-start" alignItems='center' style={{ height: '73%', marginTop: '200px' }}>
                                <Grid item ><Typography variant="h3" className={classes.headingOne} style={{ color: 'black' }}>HOW IT WORKS!</Typography></Grid>
                                <Grid container item style={{ padding: '50px' }}>
                                    <Grid item xs={12} md={3}>
                                        <Grid container direction='column' item justify="center" alignItems="center" spacing={3}>
                                            <Grid item >
                                                <Typography variant="h6" className={classes.cardHead}><span className={classes.cardNum}>1</span> DESIGN</Typography>
                                            </Grid>
                                            <Grid item  >
                                                <WobbleDiv>
                                                    <img src={design} alt="Design" className={classes.cardImg} />
                                                </WobbleDiv>
                                            </Grid>
                                            <Grid item className={classes.cardDes}>
                                                <p className={classes.cardDet}>
                                                    Select an activity below to express your creativity and make your own custom designs.
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={6}>
                                        <Grid container direction='column' item justify="center" alignItems="center" spacing={4}>
                                            <Grid item >
                                                <Typography variant="h6" className={classes.cardHead}><span className={classes.cardNum}>2</span> PRODUCE</Typography>
                                            </Grid>
                                            <Grid item container justify="flex-start" alignItems="center" direction="row" spacing={2}>
                                                <Grid item xs={12} md={6} container direction='column' justify="center" alignItems="center" spacing={4} >
                                                    <Grid item  >
                                                    <WobbleDiv>
                                                        <img src={produce} alt="Produce" className={classes.cardImg} />
                                                        </WobbleDiv>
                                                    </Grid>
                                                    <Grid item className={classes.cardDes}>
                                                        <p className={classes.cardDetTitle}>
                                                            Laser Cut your Design
                                                        </p>
                                                        <p className={classes.cardDet}>
                                                            If you don’t have access to a laser cutter at your local maker space or library, you can purchase a laser cut version from us.
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} md={6} container direction='column' justify="center" alignItems="center" spacing={4} >
                                                    <Grid item  >
                                                    <WobbleDiv>
                                                        <img src={producePrint} alt="Produce Print" className={classes.cardImg} />
                                                        </WobbleDiv>
                                                    </Grid>
                                                    <Grid item className={classes.cardDes}>
                                                        <p className={classes.cardDetTitle}>
                                                            Print Your Design at Home!                                                        </p>
                                                        <p className={classes.cardDet}>
                                                            We value accessibility so all of our activities come with a free printable version for you to create at home.                                                        </p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={3}>
                                        <Grid container direction='column' item justify="center" alignItems="center" spacing={4}>
                                            <Grid item >
                                                <Typography variant="h6" className={classes.cardHead}><span className={classes.cardNum}>3</span> ASSEMBLE</Typography>
                                            </Grid>
                                            <Grid item  >
                                            <WobbleDiv>
                                                <img src={assemble} alt="Assemble" className={classes.cardImg} />
                                                </WobbleDiv>
                                            </Grid>
                                            <Grid item className={classes.cardDes}>
                                                <p className={classes.cardDet}>
                                                    Follow the assembly instructions and make your online designs come to life!
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={2} md={2}></Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center" className={classes.sctnThree} ref={scrollDiv}>
                    <Grid container item xs={6} justify="center" alignItems="center">
                        <Grid container direction='column' justify="flex-start" alignItems='center'className={classes.activity_container} >
                            <Grid item ><Typography variant="h3" className={classes.headingOne} style={{ color: 'black', margin: '20px 0' }} >ACTIVITIES</Typography></Grid>
                            {/* <Grid item container justify="center" alignItems='center' spacing={2}>
                                <Grid xs={12} sm={6} md={3} item><Button className={classes.activityBtn}>ALL</Button></Grid>
                                <Grid xs={12} sm={6} md={3} item><Button className={classes.activityBtn}>ONLINE ONLY</Button></Grid>
                                <Grid xs={12} sm={6} md={3} item><Button className={classes.activityBtn}>MOTION AND MOVEMENT</Button></Grid>
                                <Grid xs={12} sm={6} md={3} item><Button className={classes.activityBtn}>ARTIFICIAL INTELLIENGENCE</Button></Grid>
                            </Grid> */}
                            <Grid item className={classes.container} container spacing={2} justify="center">
                                {activities}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.sctnFour}
                >
                    <Grid item xs={1} sm={2} md={2} className={classes.emptyTagContainer}></Grid>
                    <Grid container item xs={12} sm={12} md={4} justify="center" alignItems="center" className={classes.tagContainer} >
                        <Grid item xs={7} sm={8} md={12}>
                            <Typography variant="h3" className={classes.headingOne}>We Are Inventor City</Typography>
                            <Typography variant="body1" className={classes.tagDes} >
                                Over the last 10 years, we have designed and tested our activities in camps, schools, libraries, makerspaces and science centres all around Canada!
                            </Typography>
                        </Grid>
                        <Grid item xs={7} sm={3} md={5}>
                            <Button variant="contained" className={classes.headerBtn} style={{ margin: 0, padding: '10px 50px' }} 
                                href={"/about-us"}
                            >
                                <Typography className={classes.paragraph} style={{width: 'max-content'}}>
                                    LEARN MORE!                            
                                </Typography>
                            </Button>
                        </Grid>

                    </Grid>
                    <Grid item xs={1} sm={2} md={6} className={classes.emptyTagContainer}></Grid>
                    <img src={homeComp} alt="Home Comp" className={classes.homeImg} />
                </Grid>
                <Grid item container className={classes.sctnFive}>
                    <Grid item xs={1} sm={2}></Grid>
                    <Grid container item xs={12} sm={9} justify="center" alignItems="flex-start" spacing={5} direction="column" className={classes.supportContainer} >
                        <Grid item >
                            <Typography variant="h3" className={classes.supporterHead}>OUR SUPPORTERS</Typography>
                        </Grid>
                        <Grid item container spacing={3} justify="space-between" alignItems="center">
                            <Grid item sm={3}><a href="https://www.ontariosciencecentre.ca/science-at-home?gclid=Cj0KCQjwyN-DBhCDARIsAFOELTnOzyIV0es_9iBSwuniQbVQyin6YU45WZfqwng4AkMbkuLF7TO-aIYaAh75EALw_wcB"><img className={classes.supporterImg} src={ontarioScienceCentre} alt="ontarioScienceCentre" /></a></Grid>
                            <Grid item sm={3}><a href="https://steamlabs.ca/"><img className={classes.supporterImg} src={SteamLabsWordmark} alt="SteamLabsWordmark" /></a></Grid>
                            <Grid item sm={3}><a href="https://www.amazonfutureengineer.com/"><img className={classes.supporterImg} src={amazonLogo} alt="amazonLogo" /></a></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} sm={1} ></Grid>
                </Grid>
                <Grid item container className={classes.sctnSix}>
                    <Grid item xs={1} xl={2}></Grid>
                    <Grid container item xs={11} xl={8} justify="center" alignItems="center" spacing={5} >
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}>
                            <Typography variant="h3" className={classes.testimonialHead}>WHAT FOLKS ARE SAYING!</Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <Box display={{ xs: 'none', md: 'block' }}>
                                <Carousel
                                    animation={'slide'}
                                    autoPlay={true}
                                    navButtonsProps={{
                                        style: {
                                            color: '#5daac7',
                                            backgroundColor: '#fff'
                                        }
                                    }}
                                >
                                    {
                                        quoteSections.map((item, i) => {
                                            return (
                                                <Grid key={i} container direction='row' justify="center" alignItems="flex-start" spacing={2}>
                                                    {
                                                        item.map((itemN, index) => {
                                                            return (
                                                                <Grid key={index} item xs={5} >
                                                                    <QuoteBox item={itemN} />
                                                                </Grid>
                                                            );
                                                        })
                                                    }
                                                </Grid>
                                            )
                                        })
                                    }
                                </Carousel>
                            </Box>
                            <Box display={{ xs: 'none', sm: 'block', md: 'none' }}>
                                <Carousel
                                    animation={'slide'}
                                    autoPlay={true}
                                    navButtonsProps={{
                                        style: {
                                            color: '#5daac7',
                                            backgroundColor: '#fff'
                                        }
                                    }}
                                >
                                    {
                                        quote_items.map((itemN, index) => {
                                            return (
                                                <Grid key={index * 10} container direction='row' justify="center" alignItems="center" spacing={2}>
                                                    <Grid item xs={8} >
                                                        <QuoteBox item={itemN} />
                                                    </Grid>
                                                </Grid>
                                            );
                                        })
                                    }
                                </Carousel>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'none', md: 'none' }}>
                                {
                                    quote_items.map((itemN, index) => {
                                        return (
                                            <Grid key={index * 100} container direction='column' justify="center" alignItems="center" spacing={2}>
                                                <Grid item xs={12} >
                                                    <QuoteBox item={itemN} />
                                                </Grid>
                                            </Grid>
                                        );
                                    })
                                }
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} xl={2}></Grid>
                </Grid>
                <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.sctnSeven}
                >
                    <Grid item xs={1} xl={3}></Grid>
                    <Grid item xs={12} md={9} xl={6} className={classes.paperContainer}>
                        <Paper className={classes.paperEmail}>
                            <Grid container justify="center" alignItems="flex-start">
                                <Grid container item xs={12} md={6} justify="center" alignItems="center" direction="column" style={{ width: 'fit-content' }} className={classes.supportContainer} >
                                    <Grid item style={{ padding: '0 10px' }} >
                                        <Typography variant="h3" className={classes.headingOne}>JOIN THE <span style={{borderBottom: '4px solid #fff'}}>STEAMLABS</span> EMAIL LIST</Typography>
                                        <Typography variant="h6" style={{ color: '#fff', marginTop: '20px' }}>FOR FUTURE ACTIVITIES AND PROGRAMS</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} md={5} justify="center" alignItems="center" className={classes.paperEmailContainer} >
                                    <Grid item><EmailBox /></Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} xl={3}></Grid>
                </Grid>
            </Grid>
        </div >
    );
}
export default HomePage