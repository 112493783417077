import React from 'react';
import HomePage from './HomePage/HomePage';
import AboutPage from './AboutPage/AboutPage';

const Main = () => {
    return (
        <HomePage/>
    );
}

export default Main