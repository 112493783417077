import React from 'react';
import $ from 'jquery'; 
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Paper, Typography, TextField } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import QuoteBox from '../QuoteBox';
import EmailBox from '../EmailBox';
import quote_items from "../../assets/quote_items.js";
import ontarioScienceCentre from '../../assets/images/Compressed/logos/ontarioScienceCentre.jpg';
import SteamLabsWordmark from '../../assets/images/Compressed/logos/SteamLabsWordmark.jpg';
import amazonLogo from '../../assets/images/Compressed/logos/amazonLogo.png';
import inventor from '../../assets/images/Compressed/logos/inventor_logo.svg';
import mission from '../../assets/images/Compressed/logos/Mission.svg';
import vision from '../../assets/images/Compressed/logos/Vision.svg';
import AboutUsComp from '../../assets/images/Compressed/about/AboutUsComp.png';
import girlsCamp from '../../assets/images/Compressed/photos/SL_girlscamp-8.jpg';
import OSC from '../../assets/images/Compressed/photos/OSCJ.jpg';
let root_url = "https://" + window.location.hostname + "/about-us";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto ',
    },
    sctnOne: {
        width: '100%',
        backgroundColor: '#5DAAC7',
        position: 'relative',
        
        [theme.breakpoints.up('lg')]: {
            height: '882px',
        },
        [theme.breakpoints.up('xl')]: {
            height: '1200px',
        },
        [theme.breakpoints.down('md')]: {
            height: '600px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '500px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '250px',
        },
    },
    homeImg: {
        position: 'absolute',
        margin: 'auto',
        bottom: '-5%',
        zIndex: '1',
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
        },
    },
    inventorImg: {
        position: 'absolute',
        width: '30%',
        height: 'auto',
        margin: 'auto',
        bottom: '0',
        zIndex: '2',
        background: '#fff',
        padding: '2% 5%',
        borderRadius: '30px 30px 0 30px',
        [theme.breakpoints.down('sm')]: {
            width: '30%',
        },
    },
    sctnTwo: {
        height: 'fit-content',
        width: '100%',
        backgroundColor: '#f37423',
    },
    steamTag: {
        zIndex: '3',
        position: 'relative',
        padding: '1% 3%',
        width: 'fit-content',
        margin: '10px 0',
        borderRadius: '0 0 20px 20px',
        [theme.breakpoints.up('sm')]: {
            left: '47%',
        },
        [theme.breakpoints.up('md')]: {
            left: '41.6%',
        },
        [theme.breakpoints.up('lg')]: {
            left: '42%',
        },
        [theme.breakpoints.up('xl')]: {
            left: '41%',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '20px auto',
            position: 'static',
            width: 'fit-content',
            borderRadius: '20px',
            padding: '3% 5%',
        },
    },
    steamTagTxt: {
        width: 'max-content',
        fontSize: '1.5vw',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            fontSize: '6vw',
        },
    },
    steamTagBtn: {
        fontSize: '1.5vw',
        padding: '2px 5px',
        borderRadius: '0',
        backgroundColor: '#fff',
        border: '2px solid #000',
        textDecoration: 'none',
        color: '#000',
        "&:hover": {
            background: 'black',
            color: 'white',
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '6vw',
        },
    },
    sctTwoPaper: {
        borderRadius: '50px',
        margin: '40px 0 95px',
        padding: '6% 15%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '6vw',
        },
    },
    sctnThree: {
        height: 'fit-content',
        width: '100%',
        backgroundColor: '##F0F0F0',
        padding: '3% 2% 5%',
    },
    statementLogo: {
        height: '81.54px',
        width: 'auto',
    },
    sctnFour: {
        height: 'fit-content',
        width: '100%',
        backgroundColor: '#E19F26',
        padding: '3% 2% 10%',

    },
    numbers: {
        fontSize: '6vw',
        borderTop: '5px solid #fff',
        borderBottom: '5px solid #fff',
        width: '100%',
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '3.7rem',
            borderBottom: 'none',
        },
    },
    numbersText: {
        color: '#fff'
    },
    sctnFive: {
        height: 'fit-content',
        backgroundColor: '#fff',
        padding: '3% 2% 5%',
        width: '100%',
    },
    fiveHead: {
        color: '#fff',
        fontWeight: 'bold',
        textShadow: '2px 2px 8px #000',
        [theme.breakpoints.down('xl')]: {
            fontSize: '2.4vw',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '10vw'
        },
    },
    fiveContainer: {
        padding: '50px',
        backgroundColor: '#158C76',
        width: 'fit-content',
    },
    underline: {
        borderBottom: '5px solid #fff'
    },
    fiveImgFirst: {
        width: '100%',
        height: 'auto',
        maxWidth: '889px',
        maxHeight: '500px',
        borderRadius: '30px',
        position: 'absolute',
        right: '20px',
        [theme.breakpoints.down('sm')]: {
            position: 'static',
        },
    },
    fiveImgSecond: {
        width: '100%',
        height: 'auto',
        borderRadius: '30px',
        position: 'absolute',
        left: '20px',
        [theme.breakpoints.down('sm')]: {
            position: 'static',
        },
    },
    sctnSix: {
        height: 'fit-content',
        width: '100%',
        backgroundColor: '#F0F0F0',
        padding: '30px 0'
    },
    sctnSeven: {
        height: 'fit-content',
        width: '100%',
        padding: '65px 0',
        backgroundColor: '#83beb3'
    },
    sctnEight: {
        height: 'fit-content',
        width: '100%',
        backgroundColor: '#eae8e8'
    },
    headingOne: {
        color: '#fff',
        fontWeight: 'bold',
        [theme.breakpoints.down('xl')]: {
            fontSize: '50px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '30px',
        },
    },
    headingTwo: {
        color: '#fff',
        width: 'fit-content',
        margin: '10px auto',
        [theme.breakpoints.down('xl')]: {
            fontSize: '1.4rem',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '15px',
            fontSize: '1.1rem',
        },
    },
    paragraph: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '20px',
    },
    headerBtn: {
        position: 'relative',
        right: '10%',
        margin: '25px 0 40px',
        backgroundColor: "#f37423",
        color: "#fff",
        borderRadius: '29px',
        border: '2px solid #fff',
        boxShadow: '0 4px 8px 0 rgba(0,0,0, 0.3)',
        padding: '12px',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: "#d36620",
            boxShadow: '0 4px 8px 0 rgba(10,10,10, 0.8)',
        },
        [theme.breakpoints.down('xs')]: {
            right: '0',
        },
    },
    sctnNine: {
        height: 'fit-content',
        backgroundColor: '#fff',
    },
    button: {
        border: '2px solid #fff',
        borderRadius: '29px',
        backgroundColor: '#f37423',
        color: '#fff',
        boxShadow: '0 4px 8px 0 rgba(0,0,0, 0.3)',
        padding: '10px 60px',
        margin: '17px 0 0',
        fontSize: '20px',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: "#d36620",
            boxShadow: '0 4px 8px 0 rgba(10,10,10, 0.8)',
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: '20px',
            padding: '20px 30px',

        },
    },

    paperOvrly: {
        height: 'fit-content',
        width: '100%',
        zIndex: '2',
        borderRadius: '60px',
        position: 'relative',
        top: '-50px'
    },
    paperTop: {
        height: '209px',
        width: '90%',
        zIndex: '2',
        borderRadius: '60px',
        position: 'absolute',
        top: '-104.5px',
        left: '5%',
        backgroundColor: '#5daac7'
    },
    ftrAct: {
        width: '100%',
        margin: '3% auto',
    },
    carousel: {
        position: 'static',
        overflow: 'hidden',
        width: 'fit-content',
        margin: 'auto',

    },
    carouselPaper: {
        height: '100px',
        width: '200px',
        backgroundSize: 'cover',
        backgroundColor: 'rgb(248 236 212)',
        position: 'relative',
        boxShadow: '0 4px 8px 0 rgba(0,0,0, 0.8)',
        borderRadius: '15px'

    },
    carItemHead: {
        backgroundColor: '#fff',
        color: '#000',
        width: 'fit-content',
        padding: '2px',
        margin: ' 0',
        position: 'absolute',
        bottom: '6px',
        left: '6px',
        borderRadius: '6px'
    },
    cardNum: {
        fontSize: '40px',
        fontWeight: 'bold',
        marginRight: '10px'
    },
    cardHead: {
        color: '#4eb5fd',
        marginRight: '10px'
    },
    cardImg: {
        height: '260px',
        width: 'auto',
        maxWidth: '240px'
    },
    cardDes: {
        width: '90%'
    },
    cardDetTitle: {
        color: '#4eb5fd',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    cardDet: {
        textAlign: 'center',
        fontWeight: 'bold'
    },
    activityBtn: {
        border: '2px solid #4eb5fd',
        borderRadius: '29px',
        color: '#4eb5fd',
        width: '100%',
        minWidth: 'fit-content',
        margin: '20px 0',
        fontSize: '1rem'
    },
    emptyTagContainer: {
        padding: 'inherit',
        [theme.breakpoints.down('sm')]: {
            zIndex: 1,
            backgroundColor: 'rgb(93,170,199,0.86)',
            width: '100%'
        },
    },
    tagContainer: {
        padding: '75px 0',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            zIndex: 1,
            backgroundColor: 'rgb(93,170,199,0.86)',
            width: '100%'
        },
    },
    tagDes: {
        color: '#fff',
        width: '80%',
        margin: '20px 0'
    },

    supportContainer: {
        padding: '80px 0 50px',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '150px 0 50px',
        },
    },
    supporterHead: {
        borderBottom: '9px solid black',
        color: '#000',
        fontWeight: 'bold',
        width: 'fit-content',
        [theme.breakpoints.down('xl')]: {
            fontSize: '40px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '30px',
        },
    },
    supporterImg: {
        width: '100%',
        margin: '10px 0'
    },
    testimonialHead: {
        color: '#fff',
        fontWeight: 'bold',
        padding: '20px',
        backgroundColor: '#E19F26',
        width: 'fit-content',
        textShadow: '2px 2px 8px #000',
        [theme.breakpoints.down('xl')]: {
            fontSize: '40px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '30px',
        },
    },
    paperEmail: {
        height: 'fit-content',
        width: '100%',
        borderRadius: '60px',
        backgroundColor: '#E19F26',
        boxShadow: '0 4px 8px 0 rgba(0,0,0, 0.7)',
        [theme.breakpoints.only('xs')]: {
            borderRadius: '0',
        },

    },
    paperEmailContainer: {
        width: 'fit-content',
        padding: '50px 0',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '150px 0 50px',
        },
    },
    paperContainer: {
        padding: '50px',
        [theme.breakpoints.only('xs')]: {
            padding: '0',
        },
    },
    end_contact_form: {
        padding: '3% 2% 5%', 
        width: '98%',
        [theme.breakpoints.up('xl')]: {
            width: '50%',
        },
    },
    contact_fields: {
        backgroundColor: '#78c2de', 
        borderRadius: '7px 7px 0 0',
    }
}));



const HomePage = () => {
    const classes = useStyles();

    const [contactForm, setContactForm] = React.useState({
        name: '',
        email: '',
        message: ''
    });
    const [contacted, setContacted] = React.useState(false);

    let quoteSections = [];
    var i, j, tempQarray
    for (i = 0, j = quote_items.length; i < j; i += 2) {
        tempQarray = quote_items.slice(i, i + 2);
        quoteSections.push(tempQarray);
    }

    const handleChange = (event) => {
        setContactForm({ ...contactForm, [event.target.id]: event.target.value });
    };

    async function handleSubmit(event){
        event.preventDefault();
        if (contactForm.name && contactForm.email && contactForm.message){
            setContacted(true);
            const response = await fetch(root_url, {
                method: 'POST', 
                headers: {
                  'Content-Type': 'application/json',
                  'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                body: JSON.stringify({
                    email: contactForm.email,
                    my_name: contactForm.name,
                    message: contactForm.message
                }) 
            });
            const data = await response.json();
            if (data.message === "Success") {
                console.log(data.message);
            }
        }
    }

    return (
        <div className={classes.root}>
            <Grid container justify="center" alignItems="center">
                <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.sctnOne}
                >
                    <Grid container item xs={10} sm={8} md={3} justify="center">
                        <img src={inventor} alt="inventor logo" className={classes.inventorImg} />
                    </Grid>
                    <img src={AboutUsComp} alt="Home Comp" className={classes.homeImg} />
                </Grid>
                <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.sctnTwo}
                >
                    <Grid container item xs={10} sm={10} md={10} xl={6}>
                        <Paper className={classes.steamTag}><Typography variant="h6" className={classes.steamTagTxt}><strong>Powered by</strong> <a href="https://steamlabs.ca/" className={classes.steamTagBtn}><strong>steamlabs</strong></a></Typography></Paper>
                        <Paper className={classes.sctTwoPaper}>
                            <Grid container direction='column' justify="center" alignItems='center' spacing={3}>
                                <Grid item >
                                    <Typography variant="h5" align="center">
                                        <strong>
                                            We are a small team of coders, designers, and makers. We developed this website
                                            because we wanted to create accessible learning activities that not only influence
                                            users to explore their creativity, but also help them learn about science, technology,
                                            engineering, art and math - all while having fun.
                                        </strong>
                                    </Typography>
                                </Grid>
                                <Grid item >
                                    <Typography variant="h5" align="center" >
                                        <strong>
                                            Our approach to this is simple: create open ended online activities that could easily
                                            transform into hands-on experiments.
                                        </strong>
                                    </Typography>
                                </Grid>
                                <Grid item >
                                    <Typography variant="h5" align="center">
                                        <strong>
                                            From there, we've produced diverse programs that encourage creative imagination, problem-solving,
                                            and have inspired kids (and adults) to embrace the relationship between online and hands-on
                                            technologies. Along the way, we've collaborated with various science centres and educational
                                            organizations in supporting their spaces and in developing unique activities. We continue to create
                                            more playful and interactive activities in the hopes of bringing the love of science and learning
                                            to kids and adults everywhere.
                                        </strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center" className={classes.sctnThree} spacing={4}>
                    <Grid item xs={12} md={5} xl={4} direction="column" container justify="center" alignItems="center" spacing={3}>
                        <Grid item>
                            <img src={mission} alt="mission logo" className={classes.statementLogo} />
                        </Grid>
                        <Grid item>
                            <Typography align="center" variant="h4"><strong>OUR MISSION</strong></Typography>
                        </Grid>
                        <Grid item>
                            <Typography align="center" variant="h5"><strong>
                                Our mission is to make innovative technologies like artificial
                                intelligence coding and digital fabrication accessible to everyone!
                            </strong></Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5} xl={4} direction="column" container justify="center" alignItems="center" spacing={3}>
                        <Grid item>

                            <img src={vision} alt="vision logo" className={classes.statementLogo} />
                        </Grid>
                        <Grid item>
                            <Typography align="center" variant="h4"><strong>OUR VISION</strong></Typography>
                        </Grid>
                        <Grid item>
                            <Typography align="center" variant="h5"><strong>
                                A world in which humans feel confident using digital and physical tools
                                to achieve their goals and rejoice in their ability to creatively reimagine technology.
                        </strong></Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.sctnFour}
                >
                    <Grid container item xs={12} xl={9} justify="center" alignItems="center" spacing={3}>
                        <Grid item xs={12}><Typography variant="h3" className={classes.headingOne} style={{ margin: '20px' }} align="center">BY THE NUMBERS</Typography></Grid>
                        <Grid item xs={12} md={3} container justify="center" >
                            <Typography variant="h3" className={classes.numbers} align="center"><strong>3</strong></Typography>
                            <Typography variant="h6" align="center" className={classes.numbersText}>PARTNERS WHO OFFER OUR ACTIVITIES</Typography>
                        </Grid>
                        <Grid item xs={12} md={3} container justify="center">
                            <Typography variant="h3" className={classes.numbers} align="center"><strong>16</strong></Typography>
                            <Typography variant="h6" align="center" className={classes.numbersText}>SUMMER CAMPS THAT USE OUR ACTIVITIES</Typography>
                        </Grid>
                        <Grid item xs={12} md={5} container justify="center">
                            <Typography variant="h3" className={classes.numbers} align="center"><strong>835 000</strong></Typography>
                            <Typography variant="h6" align="center" className={classes.numbersText}>SCIENCE CENTRE VISITORS USING OUR ACTIVITIES</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.sctnFive}
                >
                    <Grid item container justify="center" alignItems="center" style={{ marginBottom: '150px' }} >
                        <Grid xs={12} md={6} xl={5} item container direction="column" justify="center" className={classes.fiveContainer} spacing={2}>
                            <Grid item><Typography align="center" variant="h3" className={classes.fiveHead}>STARTING AT <span className={classes.underline}>FREE!</span></Typography></Grid>
                            <Grid item><Typography variant="h6" className={classes.numbersText}>Our goal is to provide the best experience for the lowest cost!</Typography></Grid>
                            <Grid item><Typography variant="h6" className={classes.numbersText}>All of our activities come with the activity's SVG file and instructions that you can laser cut yourself. </Typography></Grid>
                            <Grid item><Typography variant="h6" className={classes.numbersText}>If you don’t have access to a laser cutter at your local maker space or library, you can purchase a laser cut version from us. </Typography></Grid>
                            <Grid item><Typography variant="h6" className={classes.numbersText}>All of our activities also have a free printable paper/ cardboard version for you to create at home.</Typography></Grid>
                        </Grid>
                        <Grid xs={12} md={6} xl={4} item container justify="center" alignItems="center" style={{ position: 'relative' }}>
                            <img src={girlsCamp} alt="Girls Camp" className={classes.fiveImgFirst} />
                        </Grid>
                    </Grid>
                    <Grid item container justify="center" alignItems="center" >
                        <Grid xs={12} md={6} xl={4} item container justify="center" alignItems="center" style={{ position: 'relative' }}>
                            <img src={OSC} alt="OSC" className={classes.fiveImgSecond} />
                        </Grid>
                        <Grid xs={12} md={6} xl={5} item container direction="column" justify="center" className={classes.fiveContainer} style={{ backgroundColor: '#1576A5' }} spacing={3}>
                            <Grid item><Typography align="center" variant="h3" className={classes.fiveHead}>DESIGN, BUILD, TEST, ITERATE!</Typography></Grid>
                            <Grid item>
                                <Typography variant="h6" className={classes.numbersText}>
                                    Over the last 10 years, we have designed and tested our activities in schools, libraries, makerspaces and science centres all around Canada!
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" className={classes.numbersText}>
                                    steamlabs has co-created 2 exhibitions with the Ontario Science Centre, the Inventorium and Inventorium 2.0 are all
                                    about inspiration and bringing ideas to life. Visitors design, build, test and iterate various projects using high-tech
                                    equipment such as laser cutters and drag-and-drop coding kiosks. Design templates on paper and kiosks guide them towards
                                    success without limiting their creative and innovative options.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container className={classes.sctnSix}>
                    <Grid item xs={1} sm={2}></Grid>
                    <Grid container item xs={12} sm={9} justify="center" alignItems="flex-start" spacing={5} direction="column" className={classes.supportContainer} >
                        <Grid item >
                            <Typography variant="h3" className={classes.supporterHead}>OUR SUPPORTERS</Typography>
                        </Grid>
                        <Grid item container spacing={3} justify="space-between" alignItems="center">
                            <Grid item sm={3}><img className={classes.supporterImg} style={{ borderRadius: '29px' }} src={ontarioScienceCentre} alt="ontarioScienceCentre" /></Grid>
                            <Grid item sm={3}><img className={classes.supporterImg} src={SteamLabsWordmark} alt="SteamLabsWordmark" /></Grid>
                            <Grid item sm={3}><img className={classes.supporterImg} src={amazonLogo} alt="amazonLogo" /></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} sm={1} ></Grid>
                </Grid>
                <Grid item container className={classes.sctnSeven}>
                    <Grid item xs={1} xl={2}></Grid>
                    <Grid container item xs={11} xl={8} justify="center" alignItems="center" spacing={5} >
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}>
                            <Typography variant="h3" className={classes.testimonialHead}>WHAT FOLKS ARE SAYING!</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display={{ xs: 'none', md: 'block' }}>
                                <Carousel
                                    animation={'slide'}
                                    autoPlay={true}
                                    navButtonsProps={{
                                        style: {
                                            color: '#5daac7',
                                            backgroundColor: '#fff'
                                        }
                                    }}
                                >
                                    {
                                        quoteSections.map((item, i) => {
                                            return (
                                                <Grid key={i} container direction='row' justify="center" alignItems="flex-start" spacing={2}>
                                                    {
                                                        item.map((itemN, index) => {
                                                            return (
                                                                <Grid key={index} item xs={5} >
                                                                    <QuoteBox item={itemN} />
                                                                </Grid>
                                                            );
                                                        })
                                                    }
                                                </Grid>
                                            )
                                        })
                                    }
                                </Carousel>
                            </Box>
                            <Box display={{ xs: 'none', sm: 'block', md: 'none' }}>
                                <Carousel
                                    animation={'slide'}
                                    autoPlay={true}
                                    navButtonsProps={{
                                        style: {
                                            color: '#5daac7',
                                            backgroundColor: '#fff'
                                        }
                                    }}
                                >
                                    {
                                        quote_items.map((itemN, index) => {
                                            return (
                                                <Grid key={index * 10} container direction='row' justify="center" alignItems="center" spacing={2}>
                                                    <Grid item xs={8} >
                                                        <QuoteBox item={itemN} />
                                                    </Grid>
                                                </Grid>
                                            );
                                        })
                                    }
                                </Carousel>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'none', md: 'none' }}>
                                {
                                    quote_items.map((itemN, index) => {
                                        return (
                                            <Grid key={index * 100} container direction='column' justify="center" alignItems="center" spacing={2}>
                                                <Grid item xs={12} >
                                                    <QuoteBox item={itemN} />
                                                </Grid>
                                            </Grid>
                                        );
                                    })
                                }
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} sm={1} xl={2}></Grid>
                </Grid>
                <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.sctnEight}
                >
                    <Grid item xs={1} xl={2}></Grid>
                    <Grid item xs={12} md={10}  xl={7} className={classes.paperContainer}>
                        <Paper className={classes.paperEmail}>
                            <Grid container justify="center" alignItems="flex-start">
                                <Grid container item xs={12} md={6} justify="center" alignItems="center" direction="column" style={{ width: 'fit-content' }} className={classes.supportContainer} >
                                    <Grid item style={{ padding: '0 10px' }} >
                                        <Typography variant="h3" className={classes.headingOne}>JOIN THE STEAMLABS EMAIL LIST</Typography>
                                        <Typography variant="h6" style={{ color: '#fff', marginTop: '20px' }}>FOR FUTURE ACTIVITIES AND PROGRAMS</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} md={5} justify="center" alignItems="center" className={classes.paperEmailContainer} >
                                    <Grid item><EmailBox /></Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} xl={2}></Grid>
                </Grid>
                <form onSubmit={handleSubmit} name="contact_form" id="contact_form" method="post" className={classes.end_contact_form}>
                    {contacted ?
                        <Grid
                            item
                            container
                            justify="center"
                            alignItems="center"
                            className={classes.sctnNine}
                            spacing={5}
                        >
                            <Grid item xs={8}><Typography variant="h3" className={classes.headingOne} style={{ color: '#1576A5' }} align="left">Thanks for contacting us. We will get in touch with you shortly.</Typography></Grid>
                            <Grid item xs={12} md={8} container justify="flex-end" >
                                <button name="return" onClick={() => {
                                    window.location.href = "https://" + window.location.hostname + "/newhome";
                                }} className={classes.button} style={{width: '100%'}}>RETURN TO ACTIVITIES</button>
                            </Grid>
                        </Grid>
                        :
                        <Grid
                            item
                            container
                            justify="center"
                            alignItems="center"
                            className={classes.sctnNine}
                            spacing={5}
                        >
                            <Grid item xs={12} ><Typography id="contact" variant="h3" className={classes.headingOne} style={{ color: '#1576A5' }} align="center">CONTACT US</Typography></Grid>
                            <Grid item xs={12} md={6} >
                                <TextField onChange={handleChange} fullWidth={true} id="name" label="Name" variant="filled" required={true} className={classes.contact_fields}/>
                            </Grid>
                            <Grid item xs={12} md={6} container justify="center" alignItems="center">
                                <TextField onChange={handleChange} fullWidth={true} id="email" label="Email" variant="filled" required={true} className={classes.contact_fields} style={{fontSize: '2rem'}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField onChange={handleChange} fullWidth={true} id="message" label="Message" variant="filled" required={true} className={classes.contact_fields} style={{fontSize: '2rem'}}/>
                            </Grid>
                            <Grid item xs={12} container justify="center" alignItems="center">
                                <input type="submit" value="SEND" name="subscribe" id="homepage-subscribe"  className={classes.button} />
                            </Grid>
                            <Grid item xs={12} direction="column" container alignItems="center" justify="center">
                                <Typography variant="h5" style={{ color: '#707070' }}>or email us at:</Typography>
                                <Typography variant="h5" style={{ color: '#707070' }}>info@inventorcity.com</Typography>
                            </Grid>
                        </Grid>
                    }
                    <input type="hidden" name="_token" value="{{ csrf_token() }}" />
                </form>
            </Grid>
        </div >
    );
}
export default HomePage