import React from 'react'
import { Paper, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        color: "#fff",
        height: 'fit-content',
        width: 'fit-content',
        padding: '50px',
        borderRadius: '29px',
        [theme.breakpoints.only('sm')]: {
            width: '500px',
        },
        [theme.breakpoints.only('xs')]: {
            padding: '10px',
            borderRadius: '0',
        },
    },
    formControl: {
        margin: '20px 0 0',
    },
    checkHead: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: '#000',
        margin: '10px 0'
    },
    checkText: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: '#000',
        margin: '2px 5px '
    },
    button: {
        border: '2px solid #fff',
        borderRadius: '29px',
        backgroundColor: '#f37423',
        color: '#fff',
        boxShadow: '0 4px 8px 0 rgba(0,0,0, 0.3)',
        padding: '10px 60px',
        margin: '17px 0 0',
        fontSize: '1.5rem',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: "#d36620",
            boxShadow: '0 4px 8px 0 rgba(10,10,10, 0.8)',
        },
    },
    emailInput: {
        width: '100%',
        height: '30px',
        borderRadius: '29px',
        color: '#000'
    },
    checkInput:{
        width: 'auto'
    },
    email_response:{
        color: '#000',
        marginTop: '30px',
        fontSize: '1.1rem',
        fontWeight: '600'
    }
}));

export default function EmailBox(){
    const [email, setEmail] = React.useState("")
    const [state, setState] = React.useState(
        {
            volunteering: false,
            kids: false,
            adults: false,
            general: false,
            teaching: false,
            online: false,
        }
    );
    const [response, setResponse] = React.useState("")

    const handleChange = (event) => {
        setState({ ...state, [event.target.id]: event.target.checked });
    };

    const { volunteering, kids, adults, general, teaching, online } = state;

    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <form action="https://steamlabs.us2.list-manage.com/subscribe/post-json" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="hiddenFrame" noValidate>
                <input type="hidden" name="u" value="2aacd21d76d4b73e8d097cf77" />
                <input type="hidden" name="id" value="fe9c2f7497" />
                <input type="hidden" name="c" value="?" />
                <span id="mc_embed_signup_scroll">
                    <iframe title="response" name="hiddenFrame" src="about:blank" style={{ display: 'none' }}></iframe>

                    <Grid container justify="center" alignItems="flex-start" direction="column" >
                        <span className="mc-field-group">
                            <label className={classes.checkHead} htmlFor="mce-EMAIL">
                                Email Address:
                    </label>
                            <input
                                type="email"
                                name="EMAIL"
                                id="mce-EMAIL"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                required
                                placeholder="   Required Field"
                                autoCapitalize="off"
                                autoCorrect="off"
                                className={classes.emailInput}
                            />
                        </span>
                        <label className={classes.checkHead}>
                            Interested In:
                        </label>
                        <label className={classes.checkText}>
                            <input
                                type="checkbox"
                                name="group[10193][1]"
                                id="volunteering"
                                checked={volunteering}
                                onChange={handleChange}
                                className={classes.checkInput}

                            />
                            <span style={{margin: '0 15px'}}>Volunteering</span>
                    </label>
                        <label className={classes.checkText}>
                            <input
                                type="checkbox"
                                name="group[10193][2]"
                                id="kids"
                                checked={kids}
                                onChange={handleChange}
                                className={classes.checkInput}
                            />
                            <span style={{margin: '0 15px'}}>Kids Classes and Events</span>
                            
                    </label>
                        <label className={classes.checkText}>
                            <input
                                type="checkbox"
                                name="group[10193][4]"
                                id="adults"
                                checked={adults}
                                onChange={handleChange}
                                className={classes.checkInput}
                            />
                            <span style={{margin: '0 15px'}}>Adults Membership and Events</span>
                            
                    </label>
                        <label className={classes.checkText}>
                            <input
                                type="checkbox"
                                name="group[10193][8]"
                                id="general"
                                checked={general}
                                onChange={handleChange}
                                className={classes.checkInput}
                            />
                            <span style={{margin: '0 15px'}}>General Newsletter</span>
                            
                    </label>
                        <label className={classes.checkText}>
                            <input
                                type="checkbox"
                                name="group[10193][16]"
                                id="teaching"
                                checked={teaching}
                                onChange={handleChange}
                                className={classes.checkInput}
                            />
                            <span style={{margin: '0 15px'}}>Teaching Resources</span>
                            
                    </label>
                        <label className={classes.checkText}>
                            <input
                                type="checkbox"
                                name="group[10193][32]"
                                id="online"
                                checked={online}
                                onChange={handleChange}
                                className={classes.checkInput}
                            />
                            <span style={{margin: '0 15px'}}>Online Activities</span>
                            
                        </label>
                        <input type="submit" value="Subscribe" name="subscribe" id="homepage-subscribe" className={classes.button} onClick={()=>{
                            if(email){
                            setResponse("Thank you for subscribing! A confirmation email has been sent to: " + email);
                            setEmail("");
                            setState({
                                    volunteering: false,
                                    kids: false,
                                    adults: false,
                                    general: false,
                                    teaching: false,
                                    online: false,
                                });
                            }else{
                                setResponse("An email is required!");
                            }
                        }}/>
                        <p className={classes.email_response}>{response}</p>

                        <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden='true' aria-label="Please leave the following three fields empty">
                            <label htmlFor="b_name">Name: </label>
                            <input type="text" name="b_name" tabIndex="-1" placeholder="Freddie" id="b_name" />

                            <label htmlFor="b_email">Email: </label>
                            <input type="email" name="b_email" tabIndex="-1" placeholder="youremail@gmail.com" id="b_email" />

                            <label htmlFor="b_comment">Comment: </label>
                            <textarea name="b_comment" tabIndex="-1" placeholder="Please comment" id="b_comment"></textarea>
                        </div>

                    </Grid>
                </span>
            </form>
        </Paper>
    )
}

