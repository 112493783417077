const carousel_items = [
    {
        name: "Looms to Laser",
        imgPath: "/images/Compressed/activities/Looms.jpg",
        imgPathFeat: "/images/Compressed/activities/featured/F-Looms.jpg",
        des: "Transform your pixel art into a laser cut loom! Use your custom loom to weave your designed fabric.",
        sitePath: "activity/loom",
    },
    {
        name: "Sunglasses",
        imgPath: "/images/Compressed/activities/A_Sunglasses.jpg",
        imgPathFeat: "/images/Compressed/activities/featured/F-SunglassesS.jpg",
        des: "Design and build your own sunglasses!",
        sitePath: "activity/sunglassesmd",
        paths: [
            ["SMALL", "activity/sunglasses"],
            ["MEDIUM", "activity/sunglassesmd"],
            ["LARGE", "activity/sunglasseslg"],
        ],
    },
    {
        name: "Dodecahedron",
        imgPath: "/images/Compressed/activities/Dodecahedron.jpg",
        imgPathFeat: "/images/Compressed/activities/featured/F-Dodec.jpg",
        des: "Design and build your own geometric shape to explore the building process of 3D structures",
        sitePath: "activity/dodecahedron",
    },
    {
        name: "Polyhedron",
        imgPath: "/images/Compressed/activities/Polyhedron.jpg",
        imgPathFeat: "/images/Compressed/activities/featured/F-Tetra.jpg",
        des: "Design and build your own geometric shape to explore the building process of 3D structures",
        sitePath: "activity/twenty4sided",
    },
    {
        name: "Tetrahedron",
        imgPath: "/images/Compressed/activities/Tetrahedron.jpg",
        imgPathFeat: "/images/Compressed/activities/featured/F-Tetra.jpg",
        des: "Design and build your own geometric shape to explore the building process of 3D structures",
        sitePath: "activity/tetrahedron",
    },
    {
        name: "Vision Recognition",
        imgPath: "/images/Compressed/activities/Vision.jpg",
        imgPathFeat: "/images/Compressed/activities/featured/F-Vision.jpg",
        des: "Learn how an AI vision recognition system works by finding its mistakes!",
        sitePath: "ai/vision",
    },
    {
        name: "Arm Gripper",
        imgPath: "/images/Compressed/activities/ArmGripper.jpg",
        imgPathFeat: "/images/Compressed/activities/featured/F-ArmGripper.jpg",
        des: "Design your own space arm and see what objects you can use to help you complete the missions in your life!",
        sitePath: "activity/grippingarm",
    },
];
export default carousel_items;
